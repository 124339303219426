<template>
<div>
<v-container>
      <v-card
        elevation=0
      >
        <v-row class="justify-center">
          <v-card-title class="my-16 text-h3 pt-16 pb-16 font-weight-bold title-color">サイゼ基礎演習</v-card-title>
        </v-row>
        <div class="pa-16" />
        <v-row class="justify-center">
          <v-col cols=12>
            <v-btn @click="toSelect" color="#009246" class="mt-16 pt-8 pb-8 text-h5 white--text" block>はじめる</v-btn>
          </v-col>
          <v-col cols=12>
            <div class="text-center">
              <v-dialog
                v-model="dialog"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#009246"
                    class="pt-8 pb-8 text-h5 white--text"
                    block
                    v-bind="attrs"
                    v-on="on"
                  >
                    サイゼ基礎演習とは
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 lighten-2 justify-center">
                    サイゼ基礎演習とは
                  </v-card-title>
                  <v-card-text>
                    サイゼリヤ基礎演習とは，有志が開発したサイゼリヤのメニュー番号を当てる非公式クイズアプリです．<br>
                    アルバイトの為にメニュー番号を覚えたい方，メニュー番号を暗記して着席後すぐに注文したい方，<br>
                    正解した商品の金額分だけ注文したい方々など...<br>
                    各個人の状況に合わせ楽しんで利用してください．
                  </v-card-text>

                  <v-card-title class="text-h5 lighten-2 justify-center">
                    メニューに関して
                  </v-card-title>
                  <v-card-text>
                    2023年6月現在の<a href="https://book.saizeriya.co.jp/menu2304_j/book/#target/page_no=1">メニュー情報</a>を参照しています．メニューが変更され次第随時更新していきます．<br>
                    メニューの訂正や報告等ありましたら<a href="https://twitter.com/inchoxd">Twitter</a>までお願いいたします．
                  </v-card-text>

                  <v-card-title class="text-h5 lighten-2 justify-center">
                    免責事項
                  </v-card-title>
                  <v-card-text>
                    当サイトにご利用されたことで直接，間接的に生じた損失に関し一切責任を負いかねます．
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="dialog = false"
                      block
                    >
                      とじる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
      </v-card>

</v-container>
      <v-footer>
        <div class="flex-grow-1"></div>
        <div>&copy;{{ new Date().getFullYear() }} incho.tech</div>
      </v-footer>
</div>
</template>

<script>
export default {
  name: 'MainTitle',
  data: () => ({
    dialog: false,
  }),
  methods: {
    toSelect() {
      this.$router.push({"name":"select"});
    }
  },
};
</script>

<style>
.title-color {
  color: #ce2b37;
}

.v-application--wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.v-footer {
  margin-top: auto;
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
