<template>
  <v-app>
    <v-main>
      <!--<TestReceiptLine/>-->
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'App',
  methods : {
    setMeta(route){
        if(route.meta.title){
          let setTitle = route.meta.title;
          document.title = setTitle;
        }
        if(route.meta.desc){
          let setDesc = route.meta.desc;
          document.querySelector("meta[name='description']").setAttribute('content', setDesc)
        }
      }
    },
  mounted(){
    let route = this.$route;
    this.setMeta(route);
  },
  watch: { 
    '$route' (route) {
      this.setMeta(route);
    }
  }
};
</script>
