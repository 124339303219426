<template>
  <div>
    <v-app-bar
      app
      color="#009246"
      dark
    >
      <v-row class="ml-2">
        <v-toolbar-title>{{ q_num + 1 }}問目</v-toolbar-title>
      </v-row>
    </v-app-bar>

    <v-container>
      <v-card
        elevation=0
      >
        <v-card-title class="subtitle-1 font-weight-bold">Q.メニュー番号から該当する料理名を選べ．</v-card-title>
      </v-card>
      <v-col>
      <v-card
        outlined
      >
        <v-row class="justify-center">
          <v-card-text class="text-h1 pt-16 pb-16" align="center">{{ question }}</v-card-text>
        </v-row>
      </v-card>
      </v-col>
      <v-col cols="12" class="justify-center">
        <div v-for="(v, i) in select_ans" :key=i>
          <v-btn class="mb-3" block outlined @click="answer(i)" :disabled=btn_disable>{{ v }}</v-btn>
        </div>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'QuizComp',
  props: {data: {type: String}},
  data: () => ({
    quiz_data: {},
    session_id: '',
    quiz_id: '',
    question: '',
    ans_data: [],
    select_ans: [],
    q_num: 0,
    finish: false,
    rst_data: {},
    btn_disable: false,
  }),
  mounted() {
    if(this.$route.query.data === undefined) {
      this.$router.push({ name: "select" });
    }
    this.quiz_data = JSON.parse(this.$route.query.data);
    this.session_id = this.quiz_data['session_id'];
    this.checkSession();
    this.quiz_id = this.quiz_data['quiz_id'];
    this.question = this.quiz_data['questions'][this.q_num]['question'];
    this.select_ans = this.quiz_data['questions'][this.q_num]['answers'];
  },
  methods: {
    checkSession() {
      axios.get(process.env.VUE_APP_API_BASE_URI+"/session/check/?sid="+this.session_id, { headers: { 'X-Forwarded-Proto': 'https' } })
        .catch(() => {
          this.$router.push({ name: "select" });
        });
    },
    answer(ans_num) {
      this.q_num += 1;
      this.btn_disable = true;
      this.ans_data.push(this.select_ans[ans_num]);
      if(this.q_num < this.quiz_data['num_of_q']) {
        this.question = this.quiz_data['questions'][this.q_num]['question'];
        this.select_ans = this.quiz_data['questions'][this.q_num]['answers'];
        this.btn_disable = false;
      } else {
        let ans_data = {
          "session_id": this.session_id,
          "quiz_id": this.quiz_id,
          "li_ans": this.ans_data
        };
          axios.post(process.env.VUE_APP_API_BASE_URI+"/quiz/answer", ans_data, { headers: { 'X-Forwarded-Proto': 'https' } })
            .then(response => {
              this.rst_data = response.data;
              this.$router.push({ name: "result", query: { rid: this.rst_data.result_id} });
              return;
            })
            .catch(error => {
              if(error.response.status != '200') {
                window.alert("エラーが発生しました");
                return;
              }
            });
      }
    }
  } 
};
</script>

