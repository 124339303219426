import Vue from 'vue'
import Router from 'vue-router'
import MainTitle from '@/components/MainTitle'
import SelectMode from '@/components/SelectMode'
import QuizComp from '@/components/QuizComp'
import GenReceiptline from '@/components/GenReceiptline'

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'title',
            component: MainTitle,
            meta: { title: 'サイゼ基礎演習', desc: 'サイゼリヤのメニュー番号をあてるクイズアプリです．(非公式)' },
        },
        {
            path: '/select',
            name: 'select',
            component: SelectMode,
            meta: { title: 'モード選択'},
        },
        {
            path: '/quiz',
            name: 'quiz',
            component: QuizComp,
            props: true,
            meta: { title: 'クイズ'},
        },
        {
            path: '/result',
            name: 'result',
            component: GenReceiptline,
            props: true,
            meta: { title: '結果発表'},
        },
    ]
})
