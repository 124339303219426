<template>
  <div>
    <v-app-bar
      app
      color="#009246"
      dark
    >
      <v-row class="ml-2">
        <v-toolbar-title>モードの選択</v-toolbar-title>
      </v-row>
    </v-app-bar>
    <v-container>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            カテゴリを指定して出題
          </v-expansion-panel-header>
          <v-expansion-panel-content>
          <v-card
            elevation=0
          >
            <v-card-text>
              指定したカテゴリの中から出題します．<br>出題するカテゴリと，出題数は以下から選択してください．<br>カテゴリは複数選択が可能です．<br>0を入力すると選択したカテゴリすべてが出題されます．
            </v-card-text>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="selected_categs"
                  :items="categs"
                  outlined
                  dense
                  label="カテゴリーを選択"
                  multiple
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="num_of_q"
                  type="number"
                  label="出題数を入力"
                  max="256"
                  min="0"
                />
              </v-col>
              <v-col>
                <v-row class="justify-center pb-8">
                  <v-btn @click="genCategReqData" color="#ce2b37" class="white--text" block>クイズ開始</v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            ページを指定して出題
          </v-expansion-panel-header>
          <v-expansion-panel-content>
          <v-card
            elevation=0
          >
            <v-card-text>
              指定したメニューのページの中から出題します．<br>出題するページと，出題数は以下から選択してください．<br>ページは複数選択が可能です．
            </v-card-text>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="selected_pages"
                  :items="pages"
                  outlined
                  dense
                  label="ページを選択"
                  multiple
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="num_of_q"
                  type="number"
                  label="出題数を入力"
                  max="256"
                  min="0"
                />
              </v-col>
              <v-col>
                <v-row class="justify-center pb-8">
                  <v-btn @click="genPageReqData" color="#ce2b37" class="white--text" block>クイズ開始</v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'SelectMode',
  data: () => ({
    session_id: '',
    selected: false,
    categs: [],
    pages: [],
    max_page: 0,
    min_page: 0,
    selected_categs: [],
    selected_pages: [],
    num_of_q: 0,
    req_data: {
      'session_id': '',
      'mdoe': 0,
      'num_of_q': 0
    },
    quiz_data: {},
  }),
  mounted() {
    this.createSession();
    this.getCategs();
    this.getPageMinMax();
  },
  methods: {
    createSession() {
      axios.get(process.env.VUE_APP_API_BASE_URI+"/session")
        .then(response => {
          this.session_id = response.data.session_id;
        })
        .catch(error => {
          window.alert(error);
        });
    },
    getCategs() {
      axios.get(process.env.VUE_APP_API_BASE_URI+"/categlist?lang=ja")
        .then(response => {
          this.categs = response.data
        })
        .catch(error => {
          window.alert(error);
        });
    },
    getPageMinMax() {
      axios.get(process.env.VUE_APP_API_BASE_URI+"/pageminmax")
        .then(response => {
          let rst = response.data
          this.max_page = rst.max_page;
          this.min_page = rst.min_page;

          for(let i=this.min_page; i <= this.max_page; i++) {
            this.pages.push(i);
          }
        })
        .catch(error => {
          window.alert(error);
        });
    },
    genCategReqData() {
      this.req_data.session_id = this.session_id;
      this.req_data.mode = 0;
      this.req_data.num_of_q = this.num_of_q;
      this.req_data.categs = this.selected_categs;

      axios.post(process.env.VUE_APP_API_BASE_URI+"/quiz", this.req_data)
        .then(response => {
          this.quiz_data = response.data;
          this.quiz_data.session_id = this.session_id;
          this.$router.push({ name: "quiz", query: { data: JSON.stringify(this.quiz_data) } });
        })
        .catch(error => {
          if(error.response.status == '400') {
            window.alert("カテゴリーを選択してください");
          }
        });
    },
    genPageReqData() {
      this.req_data.session_id = this.session_id;
      this.req_data.mode = 1;
      this.req_data.num_of_q = this.num_of_q;
      this.req_data.pages = this.selected_pages;

      axios.post(process.env.VUE_APP_API_BASE_URI+"/quiz", this.req_data)
        .then(response => {
          this.quiz_data = response.data;
          this.quiz_data.session_id = this.session_id;
          this.$router.push({ name: "quiz", query: { data: JSON.stringify(this.quiz_data) } });
        })
        .catch(error => {
          if(error.response.status == '400') {
            window.alert("ページを選択してください");
          }
        });
    }
  },
};
</script>

