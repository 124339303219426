<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <img :src="imageSrc" alt="結果発表" v-if="imageLoaded" class="img-config"/>
      </v-col>
    </v-row>
    <v-col>
      <v-row class="justify-center pb-8">
        <v-btn @click="selectMode" color="#009246" class="white--text" block>モード選択へ戻る</v-btn>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
  import axios from 'axios';
  export default {
    name: 'GenReceiptline',
    props: {data: { type: String }},
    data: () => ({
      rid: '',
      svg: '',
      rst_data: '',
      text: '',
      imageLoaded: false,
      imageSrc: '',
    }),
    
  async mounted() {
    if(this.$route.query.data !== undefined) {
      this.rid = this.$route.query.data.rid;
    }
    this.rid = this.$route.query.rid;
    const receiptline = require('receiptline');
  
    try {
      const response = await axios.get(process.env.VUE_APP_API_BASE_URI+'/result?rid='+this.rid);
      this.rst_data = JSON.parse(JSON.stringify(response.data));
      this.text = this.rst_data.receiptline;
  
      this.svg = receiptline.transform(this.text, { cpl: 48, encoding: 'cp932', spacing: true, background: 'white' });
      this.convertSVG();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
    methods: {
      convertSVG() {
        const svgBlob = new Blob([this.svg], { type: 'image/svg+xml;charset=cp932' });
        const urlCreator = window.URL || window.webkitURL;
        this.imageSrc = urlCreator.createObjectURL(svgBlob);
        this.imageLoaded = true;
      },
      selectMode() {
        this.$router.push({"name":"select"});
      }
    },
  };
</script>

<style>
.img-config {
  max-width: 100%;
  height: auto;
}
</style>
